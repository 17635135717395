import React from "react";
import { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import {
  connectWallet,
  mintFunction,
  loadCost,
  loadStatus,
  loadTotalSupply,
  loadMaxMintAmount,
  getCurrentWalletConnected,
  CheckWalletAddress,
  fetchAccountData,
  onDisconnect,
} from "./util/interact.js";



const WonderGame = () => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [WAuthorized, setWAuthorized] = useState("");
  const [MintAmount, setMintAmount] = useState("2");
  const [Cost, setCost] = useState(0);
  const [CStatus, setCStatus] = useState(false);
  const [TotalSupply, setTotalSupply] = useState(0);
  const [MaxMintAmount, setMaxMintAmount] = useState(2);
  const [MaxSupply, setMaxSupply] = useState(Number(process.env.REACT_APP_Max_Supply));
  useEffect(async () => {
    const Cost = await loadCost();
    setCost(Cost);
    const CStatus = await loadStatus();
    setCStatus(CStatus);
    const TotalSupply = await loadTotalSupply();
    setTotalSupply(TotalSupply);
    const MaxMintAmount = await loadMaxMintAmount();
    setMaxMintAmount(MaxMintAmount);
    const { address, status,WAuthorized,Provider } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    setWAuthorized(WAuthorized);
    addWalletListener(Provider);
    addWalletListener();
  }, []);

  // useEffect(async () => {
  //   const timer = setTimeout(async () =>  { const TotalSupply2 = await  loadTotalSupply();setTotalSupply(TotalSupply2); }, 60e3)
  //   return () => clearTimeout(timer)
  //  });

    

   const  addWalletListener =  (prvdr) => {
    if(prvdr!==null && typeof(prvdr) !== typeof(undefined)){
         prvdr.on("accountsChanged", async(accounts) => {
          const r = await fetchAccountData();
          setWallet(r.address);
          setStatus(r.status);
          setWAuthorized(r.WAuthorized);
          if(r.address===""){
            await onDisconnect();
          }
          else
          {
            // const tma = await loadTokensMintedPerAddress(CStatus,r.address);
            // setTokenMintedAddress(tma);
            //setMintAmount(MaxMintAmount-tma);
          }
        });
        prvdr.on("chainChanged", async (chainId) => {
          const r = await fetchAccountData();
          setWallet(r.address);
          setStatus(r.status);
          setWAuthorized(r.WAuthorized);
        });
        prvdr.on("disconnect", () => {
          onDisconnect();
        });
      }
    }
  //  function updateWallet(wa,wst,watz){
  //   setWallet(wa);
  //   setStatus(wst);
  //   setWAuthorized(watz);
  // }
  
    const connectWalletPressed = async () => {
      if(walletAddress===""){
          const walletResponse = await connectWallet();
          setStatus(walletResponse.status);
          setWallet(walletResponse.address);
          setWAuthorized(walletResponse.WAuthorized);
          //const tma = await loadTokensMintedPerAddress(CStatus,walletResponse.address);
          //setTokenMintedAddress(tma);
          //setMintAmount(MaxMintAmount-tma);
          await addWalletListener(walletResponse.Provider);
        }
      else
      {
        await onDisconnect();
        setStatus("");
        setWallet("");
        setWAuthorized("");
        //setTokenMintedAddress(0);
        //setMintAmount(MaxMintAmount);
      }
    };

  
  const onMintPressed = async () => {
     const { status } = await mintFunction(walletAddress, MintAmount);
     setStatus(status);
     if(status === "Please connect your wallet using the top right button"){
      await connectWalletPressed();
     }
  };

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      //setCStatus(false);
      return <span></span>;
    } else {
      return <div className="">
        <div className="divCountDown">
        <div><span className="divCountDownNumber">{days}</span><br/>Days</div>
        <div><span className="divCountDownNumber">{hours}</span> <br/>Hours</div>
        <div><span className="divCountDownNumber">{minutes}</span> <br/>Minutes</div>
        <div><span className="divCountDownNumber">{seconds}</span> <br/>Seconds</div>
      </div>
      </div>
      //<Countdown date={Date.now() + ((new Date(2022, 2, 18, 20, 0, 0)).getTime() - new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()).getTime() )} renderer={renderer} />
    }
  };

 
  return (
    <div>
    <div className="headerNotifications">
      <h4>
        1ST & 2ND CITIZEN MINTS SOLD OUT. <a href={`https://opensea.io/collection/wondergame-meta`} className={`nav-link nav-opensea`} target={`_blank`}>BUY ON OPENSEA</a> 
        {/* First Sale: 1223/1223 SOLD OUT! This Sale: 100 available */}
        {/* CITIZENS NEARLY SOLD OUT */}
      </h4>
    </div>
    <div className="navbar secondary-gradient w-nav" data-collapse="medium">
      <div className="navbar-container container-2 w-container">
        <a href="https://wonderlandmeta.world"  className="brand w-nav-brand">
            <img src="./wonderland.png" alt="" style={{maxWidth:'120px'}} />
        </a>
        <div className="nav-menu">
        {/* <Countdown date={Date.now() + ((new Date(2023, 0, 28, 20, 0, 0)).getTime() - new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()).getTime() )} renderer={renderer} /> */}
        </div>
        <div className="div-block-14">
            <a href="#" className="btn btn-primary btn-outline w-inline-block">
            <button id="walletButton" onClick={connectWalletPressed} title={walletAddress ==="" ? "" : "Click to disconnect"}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>CONNECT WALLET</span>
                )}
              </button>
            </a>
            
        </div>
      </div>
    </div>

    
    <div className='overflow-hidden'>
                <div className='section-3 secondary-gradient wf-section'>
                    <div className='container w-container'>
                        <div className='card text-center bg-light mb-96'>
                            <h2 className='heading h1 h1-alt'>MINT YOUR META-CITIZEN NFT</h2>
                            
                            <div className='div-block-13'>
                                <div id='w-node-eb7d0f3d-cfd8-363b-cdef-220793c76a6b-639e2827' className='embed-video'>
                                    <div  className='video w-video w-embed'>
                                    <img src="./citizen-pet.jpeg" alt="" />
                                    </div>
                                </div>
                                <div id='w-node-_5ca5b0bd-12df-cbbc-a800-b74e1aa233ad-639e2827' className='mint-info'>
                                
                              {WAuthorized==="" || process.env.REACT_APP_Check_WhiteList ==="no" ? <span></span> :
                              <h2 className='heading h1 h1-alt'>{WAuthorized ==="true" ? <span className="addressAuthorized">Wallet is whitelisted</span> : <span className="addressNotAuthorized">Your Wallet Address Has Not Been Whitelisted For Pre-Sale. Please Come Back Later Or Open A #support-ticket in Discord.</span> } </h2> }
                            
                                    <div className='mint-info-status mint-info-status-first' >
                                        <div className='text-block'>STATUS </div>
                                        {CStatus ? <div className='text-block-2'>Minting</div>  : <div className='text-block-2'>Paused</div>}
                                    </div>
                                    <div className='mint-info-status'>
                                        <div className='text-block'>PRICE</div>
                                        <div className='text-block-2'>{Cost / 1000000000000000000}  ETH</div>
                                    </div>
                                    <div className='mint-info-status'>
                                        <div className='text-block'>NETWORK</div>
                                        <div className='text-block-2'>{process.env.REACT_APP_Dev_Mode ==="1" ? process.env.REACT_APP_Test_Network_Name : process.env.REACT_APP_Main_Network_Name}</div>
                                    </div>
                                    <div className='progress-wrapper'>
                                        <h6 className='h6'>{MaxSupply - TotalSupply}/{MaxSupply} Remaining</h6>
                                        <div className="progress secondary-gradient op-20 progress-full-width">
                                            <div className='progress-bar secondary-gradient' style={ {width: `${TotalSupply / MaxSupply * 100}%` }}></div>
                                        </div>
                                    </div>
                                    <div className='form-block w-form'>
                                        <form id='email-form' name='email-form' data-name='Email Form' method='post'>
                                            <label htmlFor='Number' className='field-label'>How many NFTs to mint?</label>
                                            <div className='text-field-wrap'>
                                                <input type='number' className='text-field w-input' autoFocus={true} min='1' max={MaxMintAmount} name='txtNumber' id="txtNumber" readOnly onChange={(e) => setMintAmount(e.target.value)} defaultValue={MintAmount} required />
                                                <button type='button' className='text-field-button text-field-button-minus w-button' onClick={ (e) => { document.getElementById('txtNumber').stepDown(); setMintAmount(document.getElementById('txtNumber').value);} }>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='4' viewBox='0 0 16 4' fill='none'>
                                                        <path d='M2 2H14' stroke='#110E1D' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
                                                    </svg>
                                                </button>
                                                <button type='button' className='text-field-button text-field-button-plus w-button' onClick={ (e) => { document.getElementById('txtNumber').stepUp() ;setMintAmount(document.getElementById('txtNumber').value);} }>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
                                                        <path d='M8 2V14M2 8H14' stroke='#110E1D' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <a className='btn btn-primary primary-gradient btn-block w-button' onClick={onMintPressed} >MINT NOW</a>
                                            <br/>
                                            <p id="status">{status}</p>
                                        </form>
                                    </div>
                                    <div className="divFreeMint">
                                      <label className="field-label">3X BONUS FREE MINTS WITH YOUR CITIZEN:</label>
                                      ✅ FREE MINT: Medium Public Land Plot(valued 0.09)<br/>
                                      ✅ FREE MINT: Citizen Meta Penthouse<br/>
                                      ✅ FREE MINT: WonderLand Pet NFT<br/>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <footer className='footer footer-alt'>
                            <div id='w-node-f7124c28-2fb4-5bc4-712a-b2c51d0b11c6-97b767ee' className='inline-links'>
                                <div className="inline-link">© 2023, WonderLand Meta</div>
                            </div>
                            <div id='w-node-_7963e9da-169e-51c2-2d0f-5f5c88a12622-97b767ee' className='footer-center footer-center-alt'>
                            <a href="https://wonderlandmeta.world" >
                                <img src={'./WonderLandLogo.png'} alt='' />
                                </a>
                            </div>
                            <div id='w-node-_2f29f2fd-5552-7179-86f6-1efdad6b11f5-97b767ee' className='social-icons'>
                                <a href='https://discord.gg/zfkPDKbW7w' target={`_blank`}  className='social-item w-inline-block'>
                                    <img src={'./61c9b03061253113136fc455_Group 227.png'} alt='' />
                                </a>
                                <a href='https://twitter.com/WonderLand_Meta' target={`_blank`} className='social-item w-inline-block'>
                                    <img src={'./61c9b0308041432c4c009744_Group 225.png'} alt='' />
                                </a>
                            </div>
                        </footer>
                    </div>


                    <div className='shape shape-16'>
                        <img src={'61c99e539ca45643ff9cbb93_4.png'} alt='' />
                    </div>
                    <div className='shape shape-17'>
                        <img src={'6217bd02d3484ce1a965f923_mask_4.png'} alt='' />
                    </div>
                    <div className='shape shape-14 shape-14-alt'>
                        <img src={'6217c8f608d6f5e85f546afd_mask_group4.png'} alt='' className='image-3' />
                    </div>
                </div>
            </div>
      
    </div>
  );
};

export default WonderGame;
