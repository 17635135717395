import WonderGame from './WonderGame'
import './App.css';

function App() {
  return (
    <div className="App">
      <WonderGame></WonderGame> 
    </div>
  );
}

export default App;
