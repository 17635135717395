import { ethers } from 'ethers';
import Web3Modal from "web3modal";

require("dotenv").config();



const alchemyKey = process.env.REACT_APP_Dev_Mode === "1" ? process.env.REACT_APP_Test_Network_URL : process.env.REACT_APP_Main_Network_URL;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../WG-Contract.json");

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const CheckWhiteList = process.env.REACT_APP_Check_WhiteList;
const WhiteListURL = process.env.REACT_APP_WhiteListURL;

const NetworkID =  process.env.REACT_APP_Dev_Mode === "1" ? process.env.REACT_APP_Test_Network_Version : process.env.REACT_APP_Main_Network_Version;
const NetworkChainID = process.env.REACT_APP_Dev_Mode === "1" ? process.env.REACT_APP_Test_Network_chainId : process.env.REACT_APP_Main_Network_chainId;
const NetworkName = process.env.REACT_APP_Dev_Mode === "1" ? process.env.REACT_APP_Test_Network_Name : process.env.REACT_APP_Main_Network_Name;
const EtherscanURL = process.env.REACT_APP_Dev_Mode === "1" ? process.env.REACT_APP_Test_Etherscan_url : process.env.REACT_APP_Main_Etherscan_url
const WalletMxMint = Number(process.env.REACT_APP_Wallet_Max_Mint_Amount);
const CMaxSpp = Number(process.env.REACT_APP_Max_Supply);

export const WonderGameContract = new web3.eth.Contract(
  contractABI,
  contractAddress
);


export const loadStatus = async () => {
  const p = await WonderGameContract.methods.paused().call();
  return !p;
};

export const loadCost = async () => {
  const c = await WonderGameContract.methods.cost().call();
  return c;
};

export const loadMaxSupply = async () => {
  const ms = await WonderGameContract.methods.maxSupply().call();
  return ms;
};
export const loadTotalSupply = async () => {
  const ts = await WonderGameContract.methods.totalSupply().call();
   return ts >CMaxSpp ? CMaxSpp : ts;
};

export const loadMaxMintAmount = async () => {
  const mna = await WonderGameContract.methods.maxMintAmount().call();
  return mna;
};
export const loadSymbol = async () => {
  const smb = await WonderGameContract.methods.symbol().call();
  return smb;
};
export const loadBalanceOf = async (address) => {
  const bo = await WonderGameContract.methods.balanceOf(address).call();
  return bo;
};


export const loadWalletOfOwner = async (address) => {
  const wo = await WonderGameContract.methods.walletOfOwner(address).call();
  return wo;
};

export const loadOwnerOf = async (tid) => {
  const ad = await WonderGameContract.methods.ownerOf(tid).call();
  return ad;
};

let wcprovider=null;
const WalletConnectProvider = window.WalletConnectProvider.default;
const providerOptions = {
  walletconnect: {
      package: WalletConnectProvider,
      options: {
          rpc: {
              1 : process.env.REACT_APP_Main_Network_URL,
              5 : process.env.REACT_APP_Test_Network_URL
          }
      }
  }
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false,
  theme: {
    background: "#BFCAF7",
    main: "#000",
    secondary: "#000",
    border: "transparent",
    hover: "#fff"
  }
});

export const connectWallet = async () => {

  try {
    const _provider = await web3Modal.connect();
    wcprovider = _provider;
    return fetchAccountData();
  } catch (e) {
      return {
              address: "",
              status: "Wallet Connection Error!" ,
              WAuthorized :"",
              Provider: null,
              Balance:0
            };
  }
};

export const onDisconnect = async () => {
  await web3Modal.clearCachedProvider();
  wcprovider = null;
}

export const fetchAccountData = async () => {
  try
  {
  const library = new ethers.providers.Web3Provider(wcprovider);
  const _accounts = await library.listAccounts();
  const _Netowrk = await library.getNetwork();
  if (_Netowrk.chainId.toString() !== NetworkID) {
     if( await switchNetwork() === false)
     {
         return {
                address: "",
                status: "😥 Unable to switch network to " + NetworkName,
                WAuthorized :"",
                Provider: null,
                Balance:0
                };
     }
  }
  const _selectedAccount = _accounts.length > 0 ? _accounts[0] : "";
  return  {
          address: _selectedAccount,
          WAuthorized : await CheckWalletAddress(_selectedAccount),
          Provider: wcprovider,
          Balance: 0
        };
  }
  catch{
    return  {
      status: "Please connect using the top right button.",
      address: "",
      WAuthorized : "",
      Provider: "",
      Balance:0
    };
  }
}

const switchNetwork =async () => {
  try {
      await wcprovider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: NetworkChainID }],
      });
      return true;
  } catch (switchError) {
      return false;
  }
}

export const CheckWalletAddress = async (address) => {
  if(CheckWhiteList === "yes")
  {
    var waresponse= "false";
    try{
     await fetch(WhiteListURL  + address,{method: "GET" , headers : {"Accept": "application/json","Access-Control-Allow-Origin" :"*","Access-Control-Allow-Headers" : "Content-Type" , "Access-Control-Allow-Methods" :"GET" } })
      .then(response => response.json())
      .then(function(jsonresponse) {
        if(jsonresponse.isWhitelist === true){
          waresponse="true";
        }
        else
        {
          waresponse="false";
        }
      })
      .catch((err) => {
        waresponse="false";
    });
    }
    catch {
      waresponse="false";
    }
        return waresponse;
  }
  else
  {
    return  "true";
  }
};

export const updateMint = async (address,txHash,amnt) => {
    try{
      const m = process.env.REACT_APP_UpdateMintURL + "_Address=" + encodeURIComponent(address) + "&_TxHash=" + encodeURIComponent(txHash) + "&_MintURL=" + encodeURIComponent(window.location) +"&_MintAmount=" + encodeURIComponent(amnt);
      await fetch( m ,{method: "GET" , headers : {"Accept": "application/json","Access-Control-Allow-Origin" :"*","Access-Control-Allow-Headers" : "Content-Type" , "Access-Control-Allow-Methods" :"GET" } });
    }
    catch {
    }
};

export const getCurrentWalletConnected = async () => {
  if (web3Modal.cachedProvider) {
   return await connectWallet();
  }
  else
  {
    return {
      address: "",
      status: "" ,
      WAuthorized :"",
      Provider: null
      
    };
  }
};

export const mintFunction = async (address, message) => {
  const CStatus = await loadStatus();
  if(CStatus===false)
  {
    return {status: "❌ NFT Minting not started."};
  }
  
  if (typeof (wcprovider) == 'undefined' || wcprovider == null || address==="") {
    //await connectWallet();
    return {
      status: "Please connect your wallet using the top right button"
    };
}
  
  const wauth= await CheckWalletAddress(address);
  if(wauth === "false")
  {
    return {status: "❌ Wallet is not whitelisted."};
  }

  // const bo= Number(await loadBalanceOf(address));
  // if( (bo + Number(message)) > WalletMxMint )
  // {
  //   if(bo===WalletMxMint)
  //   {
  //     return {status: "❌ you have exceeded the max mint allowed"};
  //   }
  //   else
  //   {
  //     return {status: "❌ your maximum NFTs to mint is: " + (bo > WalletMxMint ? 0 : WalletMxMint - bo)};
  //   }
  // }
  const ts= Number(await loadTotalSupply());
  if( (ts + Number(message)) > CMaxSpp)
  {
    return {status: "❌ NFT Minting is Paused."};
  }
  
  const _library = new ethers.providers.Web3Provider(wcprovider);
  const _account = await _library.listAccounts();
  const _Netwrk = await _library.getNetwork();

  if(_Netwrk.chainId.toString() !== NetworkID.toString())
  {
    return {
      status:
        "Please switch the network in your wallet to " + NetworkName,
    };
  }

  if(_account.length === 0){
    return {
      status: "Please connect your wallet using the top right button"
    };
  }

  if (message.trim() === "0") {
    return {
      status: "❌ ",
    };
  }
  
  const a = await loadCost();
  const transactionParameters = {
    to: contractAddress, 
    from: address, 
    value: web3.utils.toHex(web3.utils.toWei((a * Number(message)).toString(), "wei")),
    //value: parseInt(web3.utils.toWei(web3.utils.toBN(a * Number(message)) ,'wei')).toString(16) ,
    data: WonderGameContract.methods.mint(address,message).encodeABI(),
  };

  
  try {
    const txHash = await wcprovider.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    await updateMint(address,txHash,message);
    return {
      status: (
        <span>
          Please check the transaction in your Wallet, or click <a target={`_blank`} href={`${EtherscanURL + txHash}`}>here</a>  to view the transaction on ehterscan.
        </span>
      ),
    };
  } catch (error) {
    return {
      status: "Wallet Error: " + error.message,
    };
  }
};


export const addTokenMetamask = async() => {
  const smb = await loadSymbol();
   await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', 
      options: {
        address: contractAddress, 
        symbol: smb, 
        decimals: 0, 
      },
    },
  });
}


//{/* <br/>
// Click <a onClick={addTokenMetamask} href="#">here</a>  to add WonderGame Metaverse NFT to your Metamask wallet. */}